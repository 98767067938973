body {
  color: #666666;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
  Parallax section
*/
.parallax-section {
  /* The image used */
  background-image: url("https://res.cloudinary.com/dba8ifej6/image/upload/v1691130621/product_comparison_background_pvblvz.png");
  background-color: #263238;

  /* Set a specific height */
  min-height: 40vh; 

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/**
  End of Parallax section
*/

.padding2em {
  padding: 2em;
}

.p2emreponsive {
  padding: 2em;
}

.bg-beige {
  background-color: #F7F7F7;
}

.bg-body {
  background-color: #666666;
}

.bg-midgray {
  background-color: #E8E8E8;
}

.bg-lightgray {
  background-color: #E5E5E5;
}

.bg-gray {
  background-color: #DADADA;
}

.bg-darkblue {
  background-color: #263238;
}

.bg-dark {
  background-color: #4C5055;
}

.text-underline {
  text-decoration: underline;
  text-underline-offset: 0.5rem;
}

.section-content-container {
  padding: 3rem;
}

.noomerLogo {
  width: 140px;
  height: auto;
}

.responsiveImg {
  width: 100%;
  height: auto;
}

.white-font {
  color: #FFF;
}

.red-font {
  color: #FF0000;
}

.green-font {
  color: #5DD662;
}

.flat-red-font {
  color: #B23B3B;
}

.flat-red2-font {
  color: #EE1D52;
}

.flat-green-font {
  color: #638E67;
}

.disabled-font {
  color: #d4d4d4;
}

.linkText {
  font-weight: bold;
  cursor: pointer;
  color: #110ED4;
}

.pointer {
  cursor: pointer;
}

.centerText {
  text-align: center;
}

.justifyText {
  text-align: justify;
}

.fontSize10 {
  font-size: 10px;
}

.fontSize12 {
  font-size: 12px;
}

.fontSize32 {
  font-size: 32px;
}

.fontSize40 {
  font-size: 40px;
}

.bf {
  font-weight: bold;
}

.width25 {
  width: 25% !important;
}

.width40 {
  width: 40% !important;
}

.width50 {
  width: 50% !important;
}

.width75 {
  width: 75% !important;
}

.width80 {
  width: 80% !important;
}

.width100 {
  width: 100% !important;
}

@media (max-width: 480px) {
  .width40 {
    width: 100% !important;
  }
  .width80 {
    width: 100% !important;
  }
  .p2emreponsive {
    padding: 0.5em;
  }
  .fontSize32 {
    font-size: 18px;
  }
}

.section-content-container:first-child {
  padding-bottom: 2rem;
  background-color: red;
}

.marquee > *:not(:last-child) {
  margin-right: 45px;
}

.marquee-component > *:not(:last-child) {
  margin-right: 8px;
  font-size: 14px;
}

/*
  Homepage search input
*/

.search_wrapper {
  position:relative;
  margin-bottom: 0;
}

.search_icon {
  height: 1.5rem;
  width: 1.5rem;
  padding: 4px 4px 4px 8px;
  position: absolute;
  box-sizing:border-box;
  top:50%;
  left:2px;
  transform: translateY(-50%);
}

.search_input {
  height: 50px;
  width: 100%;
  padding-left: 2.5rem;
  border-radius: 16px;
  background-color: transparent;
  border: 1px solid #c4c4c4;
}

@media (max-width: 1024px) {
    .search_wrapper {
      margin-bottom: 1em;
    }
    .search_input {
      height: 40px;
      width: 100%;
    }
}


.features-container:not(:last-child) {
  border: 1px solid #E8E8E8;
}

.mb05 {
  margin-bottom: 0.5em;
}

.mb1em {
  margin-bottom: 1em;
}

.mb1halfem {
  margin-bottom: 1.5em !important;
}

.mb2em {
  margin-bottom: 2em;
}

.mb3em {
  margin-bottom: 3em;
}

.elasticWrapper {
  padding: 1em;
}

.ratingContentWrapper:not(:last-child) {
  border-bottom: 1px solid #DADADA;
  padding-bottom: 1em;
}

@media (max-width: 480px) {
    .elasticWrapper {
      padding: 0;
    }
}


/**
  BOOTSTRAP TABLE STYLING
**/

.table {
width: 100%;
margin-bottom: 1rem;
color: #212529;
}

.table th,
.table td {
padding: 0.75rem;
vertical-align: top;
border-top: 1px solid #dee2e6;
}

.table thead th {
vertical-align: bottom;
border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
padding: 0.3rem;
}

.table-bordered {
border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
border: 0;
}

.table-whitebg {
  background-color: #FFF;
  color: #666666;
}

.table-striped tbody tr:nth-of-type(odd) {
background-color: #313D3F;
color: #FFF;
}

.table-striped-blue tbody tr:nth-of-type(odd) {
background-color: #C7E7FC;
//color: #FFF;
}

.table-striped-red tbody tr:nth-of-type(odd) {
background-color: #E5C0C2;
//color: #FFF;
}

.table-hover tbody tr:hover {
color: #212529;
background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
border-color: #7abaff;
}

.table-hover .table-primary:hover {
background-color: #9fcdff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
background-color: #c8cbcf;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
border-color: #8fd19e;
}

.table-hover .table-success:hover {
background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
border-color: #86cfda;
}

.table-hover .table-info:hover {
background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
border-color: #ed969e;
}

.table-hover .table-danger:hover {
background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
border-color: #fbfcfc;
}

.table-hover .table-light:hover {
background-color: #ececf6;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
border-color: #95999c;
}

.table-hover .table-dark:hover {
background-color: #b9bbbe;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
color: #fff;
background-color: #343a40;
border-color: #454d55;
}

.table .thead-light th {
color: #495057;
background-color: #e9ecef;
border-color: #dee2e6;
}

.table-dark {
color: #fff;
background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
border-color: #454d55;
}

.table-dark.table-bordered {
border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
color: #fff;
background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
.table-responsive-sm {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-sm > .table-bordered {
border: 0;
}
}

@media (max-width: 767.98px) {
.table-responsive-md {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-md > .table-bordered {
border: 0;
}
}

@media (max-width: 991.98px) {
.table-responsive-lg {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-lg > .table-bordered {
border: 0;
}
}

@media (max-width: 1199.98px) {
.table-responsive-xl {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}
.table-responsive-xl > .table-bordered {
border: 0;
}
}

.table-responsive {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
border: 0;
}

.table-responsive {
display: block;
width: 100%;
overflow-x: auto;
-webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
border: 0;
}


.search-form-control {
  padding: 8px;
  border-radius: 4px;
  width: 25%;
  margin: 8px;
}

@media screen and (max-width: 884px) {
  .search-form-control {
    width: 75%;
  }
}

/**
  END OF BOOTSTRAP TABLE STYLING
**/


/**
  Tab component styling
**/
.tabs-component [role="tablist"] {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dee2e6;
  position: relative;
  background-color: #FFF;
}

.tabs-component [role="tablist"] button {
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: block;
  padding: 0.5rem 4rem;
  cursor: pointer;
  margin: 0 1px;
  outline: none;
  background-color: #fff;
  transition: border 0.2s ease-in-out 0s;
}

.tabs-component [role="tablist"] button.active,
.tabs-component [role="tablist"] button:focus,
.tabs-component [role="tablist"] button:hover {
  border-width: 1px;
  border-color: #e9ecef #e9ecef #b41c1c;
}

.tabs-component [role="tablist"] button.active {
  color: #495057;
  background-color: #C4C4C4;
}

.tabs-component [role="tabpanel"] {
  text-align: left;
  padding: 1rem;
  background-color: #f7f7f7;
  /**box-shadow: 1px 1px 2px rgb(204 204 204 / 75%);**/
}

@media screen and (max-width: 884px) {
  .tabs-component [role="tablist"] button {
    padding: 0.5em 1em;
  }
}

@media screen and (max-width: 480px) {
  .tabs-component [role="tabpanel"] {
    padding: 0;
  }
  .tabs-component [role="tablist"] button {
    padding: 0.5em;
  }
}

.tabs-component.vertical {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.tabs-component.vertical [role="tablist"] {
  flex-direction: column;
  width: 20%;
}

.tabs-component.vertical [role="tabpanel"] {
  flex: 1;
}

.tabs-component.vertical [role="tablist"] button {
  height: 4em;
  text-align: initial;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component [role="tablist"] button {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0;
}

.tabs-component.vertical [role="tablist"] button.active,
.tabs-component.vertical [role="tablist"] button:focus,
.tabs-component.vertical [role="tablist"] button:hover {
  border-color: #e9ecef transparent #e9ecef #b41c1c;
}

/**
  End of Tab component styling
**/


/** PAGINATOR STYLING**/
[class*="css-"][class$="-pagination"]{
  text-align: right !important;
}
.pagination-button-wrapper {

}

.pagination-button {

}

.pagination-page-info {
  margin: 0 .5em;
}

/**REACT-TABLE PAGINATOR**/
.rt-pagination {
  display: flex;
  justify-content: end;
  padding-bottom: 2em;
}




/**
  Spinner
**/
.loader {
   --b: 10px;  /* border thickness */
   --n: 10;    /* number of dashes*/
   --g: 10deg; /* gap between dashes*/
   --c: red;   /* the color */

   width: 100px; /* size */
   aspect-ratio: 1;
   border-radius: 50%;
   padding: 1px;
   background: conic-gradient(#0000,var(--c)) content-box;
   -webkit-mask:
     repeating-conic-gradient(#0000 0deg,
        #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
        #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
     radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
           mask:
     repeating-conic-gradient(#0000 0deg,
        #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
        #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
     radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
   -webkit-mask-composite: destination-in;
           mask-composite: intersect;
   animation: load 1s infinite steps(var(--n));
 }
 @keyframes load {to{transform: rotate(1turn)}}
/**
  End of Spinner
**/


/**
  Help page
**/
.steps-nested-image{height: auto;}

@media screen and (max-width:480px){
  .steps-nested-image {
    width: 100%;
    height: auto;
  }
}

/**
  End of Help page
**/

/**
  Home slider
**/
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: filter 400ms, -webkit-filter 400ms;
}
.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}
.service_slide {
  position: relative;
  height: 76vh;
}

.service_slide .service-home {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 100;
}

.timeline_color_1 {
  background-color: rgba(255,255,255,0.9);
}

.timeline_color_2 {
  background-color: rgba(255,255,255,0.75);
}

